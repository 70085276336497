import React, { useCallback, useEffect, useMemo, useState } from "react";
import { hungerMoment as moment } from "../moment";
import "moment/locale/zh-tw";

import useOrderInfo from "../hooks/useOrderInfo";
import { ReactComponent as StorePlaceholder } from "../assets/icons/hun-1-group-logo-default-img.svg";
import { exportAddress } from "../helper/arrange.data";
import Loader from "./General/Loader/Loader";
import { RouteComponentProps } from "react-router-dom";
import OrderStatus from "./Feature/OrderStatus";
import CancelConfirmModal from "./Feature/CancelConfirmModal";
import NoMatch from "./General/NoMatch/NoMatch";
import PrePayStatusBar from "./Feature/PrePayStatusBar/PrePayStatusBar";
import UnableCancelPrompt from "./Feature/UnableCancelPrompt";
import classNames from "classnames";
import { ReactComponent as Check } from "../assets/icons/check-regular.svg";
import ConditionalFragment from "./common/ConditionalFragment";
import ReminderModal from "./Feature/ReminderModal";
import {
  faArrowLeft,
  faExclamationTriangle,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useAsyncPrompt from "../hooks/useAsyncPrompt";
import { isEmptyStr, isNonEmptyStr, isSet } from "../tools/format.checker";
import Modal from "./General/Modal/Modal";
import { useSearchParam, useTimeoutFn, useUpdateEffect } from "react-use";
import { faCheck, faExclamationCircle } from "@fortawesome/pro-solid-svg-icons";
import Flexbox from "./General/Flexbox/Flexbox";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export const hungerConsumerPlatformURL =
  process.env.REACT_APP_ENV === "production"
    ? "https://tw.hotcakeapp.com"
    : "https://tw-s.hotcakeapp.com";

const Main: React.FC<RouteComponentProps<{ oid: string }>> = ({ match }) => {
  const { oid } = match.params;
  const [showUnableCancel, setShowUnableCancel] = useState<boolean>(false);

  const { orderInfo, isLoading, putCancelOrderInfo, isError, getOrderInfo } =
    useOrderInfo(oid);
  const { prompt, showAsyncPrompt, handleClose, handleConfirm } =
    useAsyncPrompt();
  const canBack = useSearchParam("canBack") === "true";

  const mainUrl = useMemo(
    () => orderInfo?.store?.logoPhotoFile?.url,
    [orderInfo]
  );

  const logoUrl = useMemo(
    () => orderInfo?.store?.group?.logoPhotoFile?.url,
    [orderInfo]
  );

  const cancelable = orderInfo?.cancelableForMbr;

  const onCancelClick = useCallback(async () => {
    if (orderInfo?.cancelableForMbr) {
      const sure = await prompt();
      if (!sure) return;

      const info = await getOrderInfo();
      if (!info.cancelableForMbr) setShowUnableCancel(true);

      await putCancelOrderInfo();
      window.location.reload();
    } else {
      setShowUnableCancel(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelable]);

  if (isError) return <NoMatch />;
  if (!orderInfo) return null;

  const { city, district } = exportAddress(
    isEmptyStr(orderInfo.store.addressCountry)
      ? "TW"
      : orderInfo.store.addressCountry,
    orderInfo.store.addressCity,
    orderInfo.store.addressDistrict
  );

  return (
    <React.Fragment>
      <Loader isLoading={isLoading} />
      {orderInfo && (
        <React.Fragment>
          <PrepayCallback />
          <ReminderModal
            orderInfo={orderInfo}
            reload={getOrderInfo}
            onCancelClick={onCancelClick}
          />
          <CancelConfirmModal
            show={showAsyncPrompt}
            close={handleClose(false)}
            onConfirm={handleConfirm(true)}
          />
          <UnableCancelPrompt
            open={showUnableCancel}
            close={() => setShowUnableCancel(false)}
          />
          <div className="main">
            <div
              className={classNames(
                "pt-4 px-4",
                "w-full max-w-[30rem]",
                "flex items-center",
                "absolute",
                "z-10"
              )}
            >
              {canBack && (
                <a
                  href={`${hungerConsumerPlatformURL}/g/${orderInfo?.store.group.groupDomain}/reserved`}
                  className={classNames(
                    "mr-4",
                    "w-11 h-11",
                    "flex-shrink-0",
                    "flex justify-center items-center",
                    "text-fz3Lg text-primaryDark",
                    "bg-white bg-opacity-90",
                    "rounded-cl"
                  )}
                >
                  <FontAwesomeIcon icon={faArrowLeft} />
                </a>
              )}
              <PrePayStatusBar
                prePayStatus={orderInfo.prePayStatus}
                groupDomain={orderInfo.store.group.groupDomain}
                orderShortId={oid}
              />
            </div>
            <div className={`banner`}>
              {mainUrl && (
                <img
                  className="banner__img"
                  // onError={(e) => handleImgError("main")}
                  src={mainUrl}
                  alt="banner"
                />
              )}
            </div>
            <section className="content">
              <div className="container">
                <div className="content__logo">
                  {logoUrl ? (
                    <img
                      src={logoUrl}
                      // onError={(e) => handleImgError("logo")}
                      alt="logo"
                    />
                  ) : (
                    <StorePlaceholder
                      className="content__logo--icon"
                      // icon={["fad", "store"]}
                    />
                  )}
                </div>
                <div className="content__heading">
                  <h1 className="content__heading--title heading__1">
                    {orderInfo?.store.group.name}
                  </h1>
                  <h2 className="content__heading--title content__heading--title__sub">
                    {orderInfo.store.name}
                  </h2>
                </div>

                <ConditionalFragment condition={orderInfo.status === "WAITING"}>
                  <div className="content__greeting body">
                    <p>感謝您預約了 {orderInfo?.store.group.name}</p>
                    <p>以下是您的預約資訊</p>
                  </div>
                </ConditionalFragment>

                <ConditionalFragment
                  condition={orderInfo?.checkReserve === true}
                >
                  <div className={classNames("flex items-center", "mb-4")}>
                    <Check
                      className={classNames(
                        "w-4 h-4",
                        "p-0.5",
                        "mr-1",
                        "text-white",
                        "bg-green-2",
                        "rounded-full"
                      )}
                    />
                    <span
                      className={classNames(
                        "text-fz5 text-green-2 font-semibold"
                      )}
                    >
                      提醒確認
                    </span>
                  </div>
                </ConditionalFragment>

                <ConditionalFragment condition={orderInfo.status !== "WAITING"}>
                  <OrderStatus status={orderInfo.status} />
                </ConditionalFragment>

                <div className="content__block content__infor">
                  <h3 className="content__infor--heading">
                    <span className="content__infor--heading__date">
                      {parseDate(orderInfo?.startAt)}
                    </span>
                    <span className="content__infor--heading__time">
                      {parseTime(orderInfo?.startAt)}
                    </span>
                  </h3>
                  <ul className="content__infor--list content__infor--list__order">
                    <li className="content__infor--item">
                      <span className="content__infor--resource">
                        {orderInfo.unSpecifyResourceGroup?.name ||
                          orderInfo.resource.name}
                      </span>
                    </li>
                    <li className="content__infor--item">
                      <span className="content__infor--label">姓名</span>
                      <span className="content__infor--infor">
                        {orderInfo?.consumerNameInOrder}{" "}
                        {orderInfo?.consumerGender &&
                          GENDER[orderInfo.consumerGender]}
                      </span>
                    </li>
                    <li className="content__infor--item">
                      <span className="content__infor--label">項目</span>
                      <div className="content__infor--infor content__infor--infor__1">
                        {orderInfo.services.map(
                          ({ name, subServices, categoryName }, index) => (
                            <span
                              className="content__infor--service"
                              key={index}
                            >
                              [{categoryName}] {name}
                              <span className="content__infor--subService">
                                {subServices
                                  .map(({ name }) => ` #${name}`)
                                  .join("")}
                              </span>
                            </span>
                          )
                        )}
                      </div>
                    </li>
                    <li className="content__infor--item">
                      <span className="content__infor--label">數量</span>
                      <span className="content__infor--infor">
                        {orderInfo.amount} 位
                      </span>
                    </li>
                    <li className="content__infor--item">
                      <span className="content__infor--label">時數</span>
                      <span className="content__infor--infor">
                        {orderInfo.totalServiceMinute}分鐘&nbsp; (預計&nbsp;
                        {parseTime(orderInfo.endAt)}&nbsp;結束)
                      </span>
                    </li>
                    {!orderInfo.store.hideAddress && (
                      <li className="content__infor--item">
                        <span className="content__infor--label">店址</span>
                        <span className="content__infor--infor">
                          {`${city}${district}`}
                          <br />
                          {`${orderInfo.store.addressDetail}`}
                        </span>
                      </li>
                    )}
                    {!orderInfo.store.hidePhone && (
                      <li className="content__infor--item">
                        <span className="content__infor--label">電話</span>
                        <span className="content__infor--infor">
                          +{orderInfo.store.phoneCountryCode}-
                          {orderInfo.store.phone}
                        </span>
                      </li>
                    )}
                  </ul>
                </div>
                {(orderInfo.questionReplies.length !== 0 ||
                  orderInfo.mbrExtraDemand) && (
                  <div className="content__block">
                    <ul className="content__infor--list__questions">
                      {orderInfo.questionReplies.length !== 0 && (
                        <li className="content__infor--item">
                          <span className="content__infor--label">問題</span>
                          <span className="content__infor--infor content__infor--infor__question">
                            {orderInfo.questionReplies.map(
                              (question, index) => {
                                const reply = question.reply.filter((r) => r);
                                return reply.length === 0 ? null : (
                                  <div
                                    className="content__infor--questions"
                                    key={index}
                                  >
                                    <span className="content__infor--question">
                                      {question.question.question}
                                    </span>
                                    <span className="content__infor--answer">
                                      {question.reply}
                                    </span>
                                  </div>
                                );
                              }
                            )}
                          </span>
                        </li>
                      )}
                      {orderInfo.mbrExtraDemand && (
                        <li className="content__infor--item">
                          <span className="content__infor--label">備註</span>
                          <span className="content__infor--infor">
                            {orderInfo.mbrExtraDemand
                              .split("\n")
                              .map((s, index) => (
                                <span key={index}>{s}</span>
                              ))}
                          </span>
                        </li>
                      )}
                    </ul>
                  </div>
                )}
                {orderInfo.status !== "CHECKED_IN" &&
                  orderInfo.status !== "CANCEL" &&
                  orderInfo.status !== "CONSUMER_CANCEL" &&
                  orderInfo.status !== "NO_SHOW" &&
                  cancelable && (
                    <div className="content__btn">
                      <button
                        onClick={onCancelClick}
                        className="button content__btn--btn content__btn--btn__cancel"
                      >
                        取消預約
                      </button>
                    </div>
                  )}
              </div>
            </section>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default Main;

const WEEK_DAY = ["週日", "週一", "週二", "週三", "週四", "週五", "週六"];
const GENDER = { MALE: "先生", FEMALE: "小姐" };

const parseDate = (date: string | undefined): string =>
  date === undefined
    ? ""
    : `${moment(new Date(date)).format("YYYY/MM/DD")} (${
        WEEK_DAY[moment(new Date(date)).days()]
      })`;

const parseTime = (date: string | undefined): string =>
  date === undefined ? "" : moment(new Date(date)).format("HH:mm");

type Status = "OK" | "FAIL" | "PROCESSING" | "CANCEL";

const StatusIcon: Record<Status, IconProp> = {
  OK: faCheck,
  FAIL: faExclamationTriangle,
  PROCESSING: faExclamationTriangle,
  CANCEL: faExclamationTriangle,
};

const StatusWording: Record<Status, string> = {
  OK: "預約成功",
  FAIL: "付款失敗",
  PROCESSING: "付款處理中",
  CANCEL: "取消付款",
};

const PrepayCallback = () => {
  const [show, setShow] = useState<boolean>(false);

  const status = useSearchParam("status") as Status | undefined;
  const processMessage = useSearchParam("processMessage");
  const paymentId = useSearchParam("paymentId");
  const paymentGateway = useSearchParam("paymentGateway");

  const cleanParams = useCallback(() => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete("status");
    searchParams.delete("processMessage");
    searchParams.delete("paymentId");
    searchParams.delete("paymentGateway");

    window.history.pushState(
      {},
      "",
      `${window.location.pathname}${searchParams.toString()}`
    );
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, cancel] = useTimeoutFn(() => {
    setShow(false);
  }, 3000);

  useEffect(() => {
    if (status === "FAIL") {
      cancel();
    }
  }, [status, cancel]);

  useEffect(() => {
    if (isSet(status) && isNonEmptyStr(status)) {
      setShow(true);
    }
  }, [status]);

  useUpdateEffect(() => {
    if (show) return;
    cleanParams();
  }, [show, cleanParams]);

  const close = useCallback(() => {
    setShow(false);
  }, []);

  if (!show) return null;

  return (
    <Modal>
      <Flexbox
        align={"center"}
        justify={"center"}
        className={"px-8 w-full h-full bg-black bg-opacity-[0.64]"}
      >
        {status !== "FAIL" ? (
          <Flexbox
            direction={"col"}
            align={"center"}
            justify={"center"}
            className={"py-15 px-16 bg-white rounded-md"}
          >
            <FontAwesomeIcon
              icon={StatusIcon[status ?? "OK"]}
              className={classNames("mb-2 w-20 h-20", {
                "text-green": status === "OK",
                "text-red": status !== "OK",
              })}
              fontFamily={"sharp"}
            />
            <p className={"text-fz2 font-semibold"}>
              {StatusWording[status ?? "OK"]}
            </p>
          </Flexbox>
        ) : (
          <Flexbox
            direction={"col"}
            align={"center"}
            justify={"center"}
            className={
              "w-full max-w-[25rem] bg-grey-5 rounded-md overflow-hidden"
            }
          >
            <Flexbox
              direction={"col"}
              align={"center"}
              justify={"center"}
              className={"p-6"}
            >
              <FontAwesomeIcon
                icon={faExclamationCircle}
                className={"mb-4 w-8 h-8 text-red"}
              />
              <p className={"mb-2 text-fz3 font-semibold"}>付款失敗</p>
              <p className={"text-fz5"}>
                {paymentGateway}-{paymentId}
                <br />
                {processMessage}
              </p>
            </Flexbox>
            <button
              type={"button"}
              onClick={close}
              className={
                "py-3 w-full text-fz4 text-grey-2 text-center bg-white"
              }
            >
              關閉
            </button>
          </Flexbox>
        )}
      </Flexbox>
    </Modal>
  );
};
